<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.legalDepartment.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.legalDepartment.list.title"></app-i18n>
      </h1>

      <app-legal-department-list-toolbar></app-legal-department-list-toolbar>
      <app-legal-department-list-filter></app-legal-department-list-filter>
      <app-legal-department-list-table></app-legal-department-list-table>
    </div>
  </div>
</template>

<script>
import LegalDepartmentListFilter from '@/modules/legal-department/components/legal-department-list-filter.vue';
import LegalDepartmentListTable from '@/modules/legal-department/components/legal-department-list-table.vue';
import LegalDepartmentListToolbar from '@/modules/legal-department/components/legal-department-list-toolbar.vue';

export default {
  name: 'app-legal-department-list-page',

  components: {
    [LegalDepartmentListFilter.name]: LegalDepartmentListFilter,
    [LegalDepartmentListTable.name]: LegalDepartmentListTable,
    [LegalDepartmentListToolbar.name]: LegalDepartmentListToolbar,
  },
};
</script>

<style>
</style>
